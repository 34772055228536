import React from 'react'
import Card from './Card'
import { Link } from 'react-router-dom'

const Products = () => {
    return (
        <>
            <div className='backgrounds-set'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 ' data-aos="zoom-in"  data-aos-duration="1000">
                            <h2 className='prd-text'>Our Products</h2>
                        </div>
                        <Card />
                        <Link to="/products" className='mr-btn mt-5'>More Products</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Products
