import React, { useState, useEffect } from 'react';
import image1 from "../../assets/gynecrange/DOB-6.jpg"


const Dob = () => {
  const fetchdata = [
    {
      imageUrl: image1,
      title: 'DOB 6 TAB',
      description: 'Doxylammine 10mg+ Pyridoxine 10mg+ Folic Acid 2.5mg',
    },
  
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to update the current index and trigger a re-render
  const changeSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % fetchdata.length);
  };

  useEffect(() => {
    // Change slide every 3 seconds
    const interval = setInterval(changeSlide, 4000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  });
  return (
    <>
      <div className="container mb-5">
        <div className="row">
          <div className="">
            <div className="card-products">
              <img
                src={fetchdata[currentIndex].imageUrl}
                alt={fetchdata[currentIndex].title}
                className="custom-image-height"
              />
              <div className="card-body-products">
                <h2 className='card-products-title'><b>  {fetchdata[currentIndex].title}</b></h2>
                <span className="author-products mt-4">{fetchdata[currentIndex].description}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default Dob
