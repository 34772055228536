import Antibioticsimage from "../assets/images/Antibioticsproduct.jpg"
import Nutritionalimage from "../assets/images/Nutritionalproduct.jpg"
import Antiulcerantimage from "../assets/images/antiulcerantproducts.jpg"
import Painkillerimage from "../assets/images/Painkillerproduct.jpg"
import Coughcoldimage from "../assets/images/Cough.jpg"
import Injectable from "../assets/images/injectablesproduct.jpg"
import Othersimage from "../assets/images/others.jpg"
import gynecrange from "../assets/images/gynecrangeproduct.jpg"

const Mainproductsdata = [
    {
        id: 1,
        Image: Antibioticsimage,
        title: "Antibiotics",
        text: "Antibiotics are medications used to treat bacterial infections by either killing the bacteria or inhibiting their growth.",
        learn: "Read More",
        link: "/allantibiotics"
    },
    {
        id: 2,
        Image: Nutritionalimage,
        title: "Nutritional",
        text: "Nutritional supplements provide essential vitamins, minerals, and nutrients to support overall health and address deficiencies.",
        learn: "Read More",
        link: "/allnutritional"
    },
    {
        id: 3,
        Image: Antiulcerantimage,
        title: "Anti Ulcerant",
        text: "Anti-ulcerants are medications used to treat ulcers in the stomach or duodenum by reducing acid production or protecting the stomach lining.",
        learn: "Read More",
        link: "/allantiulcerant"
    },
    {
        id: 4,
        Image: gynecrange,
        title: "Gynec Range",
        text: "Gynecological medications are designed to address women's health issues, including menstrual disorders, hormonal imbalances.",
        learn: "Read More",
        link: "/allgynecrange"
    },
    {
        id: 5,
        Image: Painkillerimage,
        title: "Pain Killer",
        text: "Painkillers, also known as analgesics, are medications used to alleviate pain, whether it's mild, moderate, or severe.",
        learn: "Read More",
        link: "/allpainkiller"
    },
    {
        id: 6,
        Image: Coughcoldimage,
        title: "Cough/ Cold",
        text: "Cough and cold medications are used to relieve symptoms associated with upper respiratory infections, such as cough.",
        learn: "Read More",
        link: "/allcoughcold"
    },
    {
        id: 7,
        Image: Injectable,
        title: "Injectable",
        text: "They are commonly used for emergency situations, as well as for administering vaccines, insulin, antibiotics, and certain therapeutic drugs.",
        learn: "Read More",
        link: "/allinjectable"
    },
    {
        id: 8,
        Image: Othersimage,
        title: "Others",
        text: "Other medications encompass a broad category of drugs used for various medical conditions and purposes not covered by the previous categories.",
        learn: "Read More",
        link: "/allothers"
    },
   
]
export default Mainproductsdata