import React from 'react';
import Datacard from '../arrays/Datacard';
import { Link } from 'react-router-dom';

const Card = (props) => {
    return (
        <div className="container margin-inner">
            <div className="row">
                {Datacard.map(card => (
                    <div key={card.id} className="col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                        <div className="card-home-products " data-aos="fade-up"  data-aos-duration="1000">
                            <img src={card.Image} alt="" />
                            <div className="card-content">
                                <h2>{card.title}</h2>
                                <p>{card.text}</p>
                                <Link to=   {card.link} className="button">
                                READ MORE
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Card;
