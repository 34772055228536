import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import breadcrumbimage from '../assets/images/breadpro.jpg'
import Aziba from '../mainsubproducts/Antibiotics/Aziba'
import Lebar from '../mainsubproducts/Antibiotics/Lebar'
import Rtc from '../mainsubproducts/Antibiotics/Rtc'
import Seefee from '../mainsubproducts/Antibiotics/Seefee'
import Cfobar from '../mainsubproducts/Antibiotics/Cfobar'
import Ebamox from '../mainsubproducts/Antibiotics/Ebamox'
import Cefda from '../mainsubproducts/Antibiotics/Cefda'


const Allantibiotics = () => {
  return (
    <>
      <Breadcrumb title="Antibiotics" backgroundImage={breadcrumbimage} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Aziba />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Lebar />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Rtc />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Seefee />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Cfobar />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ebamox />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Cefda />
          </div>
        </div>
      </div>
    </>
  )
}

export default Allantibiotics
