import React from 'react';
import Mainproductsdata from '../arrays/Mainproductsdata';
import { Link } from 'react-router-dom';

const Mainproducts = (props) => {
    return (
        <>
            <div className='container'>
                <div className='row'>
                    {Mainproductsdata.map(Mpdata => (
                        <div key={Mpdata.id} className='col-lg-4 col-md-6 col-12 mb-4'>
                            <div className="card-hover " data-aos="zoom-in" data-aos-duration="1000">
                                <div className="card-hover__content">
                                    <h3 className="card-hover__title">
                                        {Mpdata.title}
                                    </h3>
                                    <p className="card-hover__text">{Mpdata.text}</p>
                                    <Link to={Mpdata.link} className="card-hover__link">
                                        <span>{Mpdata.learn}</span>
                                    </Link>
                                </div>
                                <img src={Mpdata.Image} alt="" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Mainproducts;
