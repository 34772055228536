import React, { useState } from "react";
import { Link } from 'react-router-dom';

const Form = () => {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <>
      <section>
        <div className="section-header">
          <div className="container">
            <h2
              className="main-ctc-head pt-3 "
              data-aos="zoom-out"
              data-aos-duration="1000"
            >
              EBAR HEALTHCARE
            </h2>
            <h3 className="" data-aos="zoom-out" data-aos-duration="1000">
              SCIENCE OF GROWTH
            </h3>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-6 col-12 contact-info ctc-bg-img py-5 "
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <h2 className="ctc-touch">Stay In Touch</h2>
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-home"></i>
                </div>
                <div className="contact-info-content">
                  <h4>Office</h4>
                  <p>
                    B/408, Signature II Sarkhej <br /> Cross Road, Sarkhej
                    -382219
                  </p>
                </div>
              </div>
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-home"></i>
                </div>
                <div className="contact-info-content">
                  <h4>Warehouse</h4>
                  <p>
                    194, 195, 196 - Shivam Industrial Park, <br /> Near Intas,
                    Ahmedabad - 382213
                  </p>
                </div>
              </div>
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-phone"></i>
                </div>
                <div className="contact-info-content">
                  <h4>Phone</h4>
                  <Link to="tel:+917574041101">+917574041101</Link>
                </div>
              </div>
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-envelope"></i>
                </div>
                <div className="contact-info-content">
                  <h4>Email</h4>
                  <Link to="emailto:info@ebarhealthcare.com">
                    info@ebarhealthcare.com{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-6 col-12 "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="contact-form-wrapper d-flex justify-content-center">
                <form action="https://digitalsolitario.com/ebarthealth.php" method="post" className="contact-form">
                  <h5 className="title">Contact us</h5>
                  <p className="description my-4">
                    Feel free to contact us if you need any assistance, any
                    help, or another question.
                  </p>
                  <div>
                    <input
                      name="fullname"
                      type="text"
                      className="form-control rounded border-white mb-4 form-input"
                      id="full_name"
                      placeholder="Full Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      name="number"
                      type="number"
                      className="form-control rounded border-white mb-4 form-input"
                      id="phone_number"
                      placeholder="Enter Number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      name="email"
                      type="email"
                      className="form-control rounded border-white mb-4 form-input"
                      placeholder="Email"
                      id="form_email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <textarea
                      name="textmessage"
                      id="form_message"
                      className="form-control rounded border-white mb-4 form-text-area"
                      rows="5"
                      cols="30"
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div className="submit-button-wrapper pt-3">
                  <input type="submit" name="submit" value="Send"/>
                  </div>
                
                </form>
              </div>
            </div>
            <div className="col-12 mt-5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3675.388415140335!2d72.42390057531013!3d22.899040179260485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDUzJzU2LjUiTiA3MsKwMjUnMzUuMyJF!5e0!3m2!1sen!2sin!4v1698988807075!5m2!1sen!2sin"
                className="map"
                loading="lazy"
                title="map"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Form;
