import React from 'react';

function Breadcrumb({ title, backgroundImage }) {
  return (
    <div className="breadcrumbs" style={{ backgroundImage: `url(${backgroundImage})`, backgroundBlendMode: 'multiply', backgroundColor: '#565656' }}>
  
    <div className="container">
      <div className="row">
        <div className="col-12">
            <h2>{title}</h2>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Breadcrumb;
