import React from 'react';
import { Link } from 'react-router-dom';
import footerlogo from '../assets/images/footer-logo.png';

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="main-footer">
          <div className="container">
            <div className="row justify-content-between align-items-end">
              <div className="col-lg-8 col-12 ">
                <div className="info">
                  <div className="logo">
                    <img src={footerlogo} className='img-fluid' style={{ maxWidth: "30%" }} alt="Ebar Healthcare Logo" />
                  </div>
                  <div className="part-txt">
                    <p>
                      At Ebar, we're committed to excellence in research, development, and quality assurance. Our core values revolve around integrity, innovation, and collaboration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 ">
                <div className="links">
                  <h3>Media Links</h3>
                  <div className='faicons mb-4'>
                    <div className='Whatsapp'>
                      <Link to="https://api.whatsapp.com/send/?phone=7574041101&text=Want+to+know+more+about+your+products.&type=phone_number&app_absent=0" >
                        <span className='all-icons'>
                          <i className="fa fa-whatsapp" aria-hidden="true"></i>
                        </span>
                      </Link>
                    </div>
                   
                    <div className='Linkdin mx-3'>
                      <Link to="https://in.linkedin.com/in/amit-sheth-85b0b418" >
                        <span className='all-icons'>
                          <i className="fa fa-linkedin" aria-hidden="true"></i>
                        </span>
                      </Link>
                    </div>
                    
                  </div>
                  <div className="basic-info mb-4">
                    <p> 194, 195, 196 - Shivam Industrial Park, <br/> Near Intas,  Ahmedabad - 382213</p>
                  </div>
                  <div className='basic-info'>
                  <Link to="mailto:info@ebarhealthcare.com" style={{color:"black" , fontWeight:"800"}} >
                  info@ebarhealthcare.com
                      </Link>
                    <p>
                
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="part-txt">
              <p>Copyright &copy; All Right Reserved by <Link to="/" alt="Ebar Health Care" title="Ebar Heatlcare">Ebar Healthcare</Link></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
