import React, { useState } from 'react';
import '../App.css';
import logo from "../assets/images/lo.png";
import { NavLink } from 'react-router-dom';

const Navbar = () => {

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const closeNavMenu = () => {
    if (!isNavCollapsed) {
      handleNavCollapse();
    }
  };
  
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <img src={logo} className='company-logo' alt='logo' />
        </NavLink>
        <button
          className={`navbar-toggler ${isNavCollapsed ? '' : 'collapsed'}`}
          type="button"
          onClick={handleNavCollapse}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isNavCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={closeNavMenu}>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about" onClick={closeNavMenu}>
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/products" onClick={closeNavMenu}>
                Products
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/manufacturing" onClick={closeNavMenu}>
                Manufacturing
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/careers" onClick={closeNavMenu}>
                Careers
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" onClick={closeNavMenu}>
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>

      </div>
    </nav>
  );
};

export default Navbar;
