import React, { useEffect } from 'react';
import breadcrumbimage from '../assets/images/manufacturingtvbread.avif';
import Breadcrumb from '../components/Breadcrumb';
import Manu from '../assets/images/manufacturing-1.jpg';
import Manufacture from '../arrays/Manufacture';

const Manufacturing = (props) => {
  useEffect(() => {
    document.title = 'Ebar Healthcare - Manufacturing';
  }, []);
  return (
    <>
      <Breadcrumb title="Manufacturing" backgroundImage={breadcrumbimage} />
      <div className='container my-5'>
        <div className='row py-4'>
          <div className='col-12'>
            <div className='title-box-manu ' data-aos="zoom-in-up"  data-aos-duration="1000">
              <h4 className='title-box_title'>About Manufacturing</h4>
              <h2 className='my-3 sec-title-manu'>State Of The Art Manufacturing</h2>
              {/* You can remove the following lines with multiple chevron icons */}
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          
          </div>
         
        </div>
        <div className='row'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ' data-aos="fade-up"  data-aos-duration="1000">
        <p className='pt-3 manufacturing-p'>EBAR HEALTHCARE’S product development is under the technical guidance of Serene Healthcare, Uzbekistan, a leading pharmaceutical company with over 20 years of experience.</p>
        <p className='manufacturing-p'>Ebar Healthcare is committed to all aspects of the pharmaceutical manufacturing process, from drug discovery to drug distribution. The company is committed to providing safe and effective products that meet the needs of patients around the world.</p>
        <p className='manufacturing-p'>Our Pharmaceutical Manufacturing Plants are equipped with advanced technology and adhere to rigorous quality control measures, ensuring precision and excellence in every step of the production process</p>
        
          </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
            <img className='img-fluid ' alt='' data-aos="fade-up" data-aos-duration="1000" src={Manu} />
          </div>
        </div>
        <div className="row mt-5">
          {/* Step 1 */}
          <div className=' col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4'>
            <div className="card-manufacturing " data-aos="fade-up"  data-aos-duration="1000">
              <div className="step mb-3">
                <div className="step__title">{Manufacture[0].Step1}</div>
                <div className="step__info">{Manufacture[0].content1}</div>
              </div>
            </div>
          </div>

          {/* Step 2 */}
          <div className=' col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4'>
            <div className="card-manufacturing " data-aos="fade-up" data-aos-duration="1000">
              <div className="step mb-3">
                <div className="step__title">{Manufacture[1].Step2}</div>
                <div className="step__info">{Manufacture[1].content2}</div>
              </div>
            </div>
          </div>

          {/* Step 3 */}
          <div className=' col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4'>
            <div className="card-manufacturing " data-aos="fade-up" data-aos-duration="1000">
              <div className="step mb-3">
                <div className="step__title">{Manufacture[2].Step3}</div>
                <div className="step__info">{Manufacture[2].content3}</div>
              </div>
            </div>
          </div>

          {/* Step 4 */}
          <div className=' col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4'>
            <div className="card-manufacturing " data-aos="fade-up" data-aos-duration="1000">
              <div className="step mb-3">
                <div className="step__title">{Manufacture[3].Step4}</div>
                <div className="step__info">{Manufacture[3].content4}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Manufacturing;
