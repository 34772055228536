import Antibiotics from '../assets/images/antibioticsdark.jpeg'
import Nutritional from '../assets/images/nutritionaldark.jpeg'
import Antiulcerant from '../assets/images/antiulcerantdark.jpeg'

const Datacard = [
    {
        id: 1,
        Image: Antibiotics,
        title: "Antibiotics",
        text: "Antibiotics are medications used to treat bacterial infections by either killing the bacteria or inhibiting their growth.",
        link: "/allantibiotics"
    },
    
    {
        id: 2,
        Image: Nutritional,
        title: "Nutritional",
        text: "Nutritional supplements provide essential vitamins, minerals, and nutrients to support overall health and address deficiencies.",
        link: "/allnutritional"
    },
    {
        id: 3,
        Image: Antiulcerant,
        title: "Anti Ulcerant",
        text: "Anti-ulcerants are medications used to treat ulcers in the stomach or duodenum by reducing acid production or protecting the stomach lining.",
        link: "/allantiulcerant"
    }
]

export default Datacard