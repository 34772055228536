import React from 'react'

const Leftabout = ({image,heading, text}) => {
  return (
    <>
    <div className='container'>
        <div className='row'>
            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                <img className='img-fluid updated phone-image '  data-aos="fade-up"  data-aos-duration="1000" src={image} alt=''/>
            </div>
            <div className='col-lg-9 col-md-9 col-sm-6 col-12 ' data-aos="fade-down"  data-aos-duration="1000">
                <div className='aboutus-v'>
                    <h4>{heading}</h4>
                </div>
                <div className='aboutus-v'>
                   <p>{text}</p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Leftabout
