import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import breadcrumbimage from '../assets/images/breadpro.jpg'
import Ebvita from '../mainsubproducts/Nutritional/Ebvita'
import Ebaprot from '../mainsubproducts/Nutritional/Ebaprot'
import Ebar from '../mainsubproducts/Nutritional/Ebar'
import Odzpowder from '../mainsubproducts/Nutritional/Odzpowder'
import Gg from '../mainsubproducts/Nutritional/Gg'
import Ed3 from '../mainsubproducts/Nutritional/Ed3'
import Ebzyme from '../mainsubproducts/Nutritional/Ebzyme'
import Ebafolm from '../mainsubproducts/Nutritional/Ebafolm'
import Muvi from '../mainsubproducts/Nutritional/Muvi'


const Allnutritional = () => {
  return (
    <>
      <Breadcrumb title="Nutritional" backgroundImage={breadcrumbimage} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ebvita />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ebaprot />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ebar />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Odzpowder />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Gg />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ed3 />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ebzyme />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ebafolm />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Muvi />
          </div>
        </div>
      </div>
    </>
  )
}

export default Allnutritional
