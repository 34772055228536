import React, { useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import breadcrumbimage from "../assets/images/breadcrumb2.jpg";
import aboutlong from "../assets/images/aboutlong2.png";
import Abtdata from "../arrays/Abtdata";
import Leftabout from "../components/Leftabout";
import ourvision from "../assets/images/1.png"
import ourmission from "../assets/images/2.png"
import ourvalues from "../assets/images/3.png"
import Rightabout from "../components/Rightabout";
import Valuesabt from "../components/Valuesabt";

const About = (props) => {
  useEffect(() => {
    document.title = 'Ebar Healthcare - About Us';
  }, []);
  return (
    <>
      <Breadcrumb title="About Us " backgroundImage={breadcrumbimage} />
      <div className="main-about">
      <section className=" clinic-about-area" >
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="ca-about-left clinic-about-left " data-aos="fade-down"  data-aos-duration="1000">
                <h2 className='ebar-heading'>About Us</h2>
                <p>Founded in 2007, EBAR HEALTHCARE is a leading pharmaceutical company focused on the development, manufacturing, and commercialization of high-quality medicines.
At EBAR, we have a team of over 125 field forces working in different parts of the country, and it is committed to providing quality products at an economical range.
</p>
                <p>Our team's enthusiasm for healthcare and a sales staff with best-in-class expertise are the building blocks of our success.</p>
                <p>We think that engaging doctors in a meaningful and significant way will help to promote our products. We stand behind the premium quality of products we provide into the domestic market using a combination of our own manufacturing facilities.</p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12 d-flex justify-content-center">
              <div className="ca-about-right clinic-about-right">
                <div className="ca-about-img clinic-about-img">
                  <img className='img-fluid ' src={aboutlong}  data-aos="fade-up"  data-aos-duration="1000" alt="About" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {Abtdata.map(abt => (
              <div key={abt.id} className="col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="box-shadow mt-3 mb-3 " data-aos="fade-up"  data-aos-duration="1000">
                  <div className="inn-left">
                    <i className="fa fa-check fa-2x" aria-hidden="true"></i>
                  </div>
                  <div className="inn-right">
                    <h5 style={{ fontSize: "22px" }}>{abt.info}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="abt-background">
        <Leftabout
          image={ourvision}
          heading=" Vision"
          text="Our vision is to make a positive impact on the world, improving the health and well-being of people everywhere. It is to produce and commercialize high-quality medications at competitive prices in order to improve healthcare for a better~ society and across the globe."
        />
        <Rightabout
          image={ourmission}
          heading=" Mission"
          text="The company operates in 16 states and has a wide range of products, including Nutra, gynec, derma, opthal and general physician range. Providing accessible and variety of modern, value-added, and research-based products to every individual and along with that becoming an ethical, open, spirited, and energetic organization with a forward-looking Vision. "
        />
         <Valuesabt
          image={ourvalues}
          heading=" Values"
          text="We uphold the highest ethical standards in everything we do. Integrity is the foundation of our relationships with all stakeholders, from patients and healthcare professionals to our employees and partners. Quality is non-negotiable. "
        />
      </section>
      </div>
    </>
  );
};

export default About;
