import React, { useEffect } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import breadcrumbimage from '../assets/images/careersbread.avif'
import TabComponent from '../components/TabComponent'

const Careers = () => {
  useEffect(() => {
    document.title = 'Ebar Healthcare - Careers';
  }, []);
  return (
    <>
    <Breadcrumb title="Careers" backgroundImage={breadcrumbimage} />
    <TabComponent/>
    
    </>
  )
}

export default Careers
