import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import breadcrumbimage from '../assets/images/breadpro.jpg'
import Dicof from '../mainsubproducts/Cough/Dicof'
import Ticof from '../mainsubproducts/Cough/Ticof'
import Picof from '../mainsubproducts/Cough/Picof'
import Salcough from '../mainsubproducts/Cough/Salcough'
import Algo from '../mainsubproducts/Cough/Algo'
import Ecoz from '../mainsubproducts/Cough/Ecoz'
import Dey from '../mainsubproducts/Cough/Dey'



const Allcoughcold = () => {
  return (
    <>
      <Breadcrumb title="Cough/Cold" backgroundImage={breadcrumbimage} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Dicof />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ticof />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Picof />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Salcough />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Algo />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ecoz />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Dey />
          </div>
        </div>
      </div>
    </>
  )
}

export default Allcoughcold
