import React, { useState, useEffect } from 'react';
import image1 from "../../assets/Antibiotics/SEEFEE-100.jpg"
import image2 from "../../assets/Antibiotics/SEEFEE-200.jpg"
import image3 from "../../assets/Antibiotics/SEEFEE-O.jpg"
import image4 from "../../assets/Antibiotics/SEEFEE-200_(1).jpg"

const Seefee = () => {
    const fetchdata = [
        {
            imageUrl: image1,
      title: 'SEEFEE 100',
            description: 'Cefixime 200mg/ 50mg',
        },
        {
            imageUrl: image2,
      title: 'SEEFEE 200',
            description: 'Cefixime 200mg/ 50mg',
        },
        {
            imageUrl: image3,
      title: 'SEEFEE-O',
            description: 'Cefixime 200mg/ 50mg',
        },
        {
            imageUrl: image4,
      title: 'SEEFEE-200',
            description: 'Cefixime 200mg/ 50mg',
        },
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

  // Function to update the current index and trigger a re-render
  const changeSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % fetchdata.length);
  };

  useEffect(() => {
    // Change slide every 3 seconds
    const interval = setInterval(changeSlide, 4000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  });
  return (
  <>
 <div className="container mb-5">
    <div className="row">
      <div className="">
        <div className="card-products">
          <img
            src={fetchdata[currentIndex].imageUrl}
            alt={fetchdata[currentIndex].title}
            className="custom-image-height"
          />
          <div className="card-body-products">
            <h4 className='card-products-title'><b>  {fetchdata[currentIndex].title}</b></h4>
            <span className="author-products mt-4">{fetchdata[currentIndex].description}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}


export default Seefee
