import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import breadcrumbimage from '../assets/images/breadpro.jpg'
import Albarothers from '../mainsubproducts/others/Albarothers'
import Alkiothers from '../mainsubproducts/others/Alkiothers'
import Egmothers from '../mainsubproducts/others/Egmsothers'
import Esbarothers from '../mainsubproducts/others/Esbarothers'
import Exxonothers from '../mainsubproducts/others/Exxonothers'
import Ezyothers from '../mainsubproducts/others/Ezyothers'
import Meflud from '../mainsubproducts/others/Meflud'
import Vrs from '../mainsubproducts/others/Vrs'

const Allothers = () => {
  return (
    <>
       <Breadcrumb title="Others" backgroundImage={breadcrumbimage} />
    <div className='container mt-5'>
      <div className='row'>
        <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
          <Albarothers />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
          <Alkiothers />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
          <Egmothers />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
          <Esbarothers />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
          <Ezyothers />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
          <Meflud />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
          <Vrs />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
          <Exxonothers />
        </div>
      </div>
    </div>
    </>
 
  )
}

export default Allothers