import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import breadcrumbimage from '../assets/images/breadpro.jpg'
import Act from '../mainsubproducts/Painkillerr/Act'
import Asm from '../mainsubproducts/Painkillerr/Asm'
import Clefun from '../mainsubproducts/Painkillerr/Clefun'
import Dipar from '../mainsubproducts/Painkillerr/Dipar'
import Etro from '../mainsubproducts/Painkillerr/Etro'
import Fan from '../mainsubproducts/Painkillerr/Fan'
import Mapds from '../mainsubproducts/Painkillerr/Mapds'
import Nimar from '../mainsubproducts/Painkillerr/Nimar'
import Pavan from '../mainsubproducts/Painkillerr/Pavan'
import Pidt from '../mainsubproducts/Painkillerr/Pidt'
import Rtcpainkiller from '../mainsubproducts/Painkillerr/Rtcpainkiller'



const Allpainkiller = () => {
  return (
    <>
      <Breadcrumb title="Painkiller" backgroundImage={breadcrumbimage} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Act />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Asm />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Clefun />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Dipar />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Etro />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Fan />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Mapds />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Nimar />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Pavan />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Pidt />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Rtcpainkiller />
          </div>
        </div>
      </div>
    </>
  )
}

export default Allpainkiller
