import React from 'react'
import { Link } from 'react-router-dom'

const Textright = ({title,heading, description1, description2, imagePath, linkTo}) => {
  return (
   <>
   <section
        className="creative-agency-about-area clinic-about-area left-colimage"
        data-scroll-index="2" id='background-image_textright'
      >
        <div className="container">
          <div className="row d-flex align-items-center">
          <div className="col-xl-6 col-lg-6 d-flex justify-content-center ">
              <div className="ca-about-right clinic-about-right">
                <div className="ca-about-img clinic-about-img">
                  <img
                  className='img-fluid indian-map '
                    src={imagePath}
                    alt="Architecture About"
                    data-aos="fade-up"  data-aos-duration="1000"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 ">
              <div className="ca-about-left clinic-about-left " data-aos="fade-down" data-aos-duration="1000">
                <h5 className='wlc'>{title}</h5>
                <h2 className='ebar-heading'>{heading}</h2>
                <p>
                  {description1}
                </p>
                <p>
                  {description2}
                </p>
                <div className="ca-about-btn-wrapper clinic-about-btn-wrapper">
                  <Link className="ca-about-btn clinic-about-btn" to={linkTo}
                  >Learn More</Link >
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section></>
  )
}

export default Textright