import React, { useState, useEffect } from 'react';
import image2 from "../../assets/others/EXXON-OD.jpg"
import image3 from "../../assets/others/EXXON-P.jpg"

const Exxonothers = () => {
  const imageData = [
    {
      imageUrl: image2,
      title: 'EXXON-OD Tab',
      description: 'Methylcobalamine 1500 mcg + Folic Acid 1.5mg + Vit. B1 10mg + Vit B6 #mg + Alpha Lipoic Acid 100mg',
    },
    {
      imageUrl: image3,
      title: 'EXXON-P Tab',
      description: 'Pregabalin 75 = Notriptyline 10 + Methylcobalamine 1500 mcg',
    },
    
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to update the current index and trigger a re-render
  const changeSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageData.length);
  };

  useEffect(() => {
    // Change slide every 3 seconds
    const interval = setInterval(changeSlide, 4000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  });

  return (
    <div className="container mb-5">
    <div className="row">
      <div className="">
        <div className="card-products">
          <img
            src={imageData[currentIndex].imageUrl}
            alt={imageData[currentIndex].title}
            className="custom-image-height"
          />
          <div className="card-body-products">
            <h4 className='card-products-title'><b>  {imageData[currentIndex].title}</b></h4>
            <span className="author-products mt-4">{imageData[currentIndex].description}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Exxonothers;
