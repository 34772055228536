import React, { useState } from 'react';

const CareersComponent = ({ title, vacancies, time, designation }) => {
    const [formData, setFormData] = useState({
        resumeName: '',
        resumenumber: '',
        resumeEmail: '',
        jobProfile: '',
        currentLocation: '',
        currentDesignation: '',
    });


    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormSubmit = () => {
        if (formData.resumeName && formData.resumenumber && formData.resumeEmail && formData.jobProfile) {

            const message = `Name: ${formData.resumeName}
            %0AEmail: ${formData.resumeEmail}
            %0APhone: ${formData.resumenumber}
            %0ACurrent Location: ${formData.currentLocation}
            %0ACurrent Designation: ${formData.currentDesignation}
            %0AProfile: ${formData.jobProfile}`;

            const whatsappNumber = '7574041101';
            const whatsappMessage = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`;

            window.open(whatsappMessage, '_blank');

            alert('Message sent on WhatsApp');

            setFormData({
                resumeName: '',
                resumenumber: '',
                resumeEmail: '',
                jobProfile: '',
                currentLocation: '',
                currentDesignation: '',
            });
        } else {
            alert('Please fill out all the required fields before sending.');
        }
    };


    return (
        <>

            <div className="item-careers" data-cats="category1" style={{ display: 'block' }}>
                <div className="row">
                    <div className="col-12">
                        <p className="title-type-careers">{title}</p>
                        <ul className="list-unstyled d-lg-flex careers-card-list my-1">
                            <li className="me-4 d-flex align-items-center">
                                <i className="fa fa-user icon-col" aria-hidden="true"></i>
                                <span className="label-color-1 mx-2 fs-16 fw-400">{designation}</span>
                            </li>
                            <li className="me-4 d-flex align-items-center">
                                <i className="fa fa-clock-o icon-col" aria-hidden="true"></i>
                                <span className="label-color-1 mx-2 fs-16 fw-400"> {time}</span>
                            </li>
                            <li className="me-4 d-flex align-items-center">
                                <i className="fa fa-users icon-col" aria-hidden="true"></i>
                                <span className="label-color-1 mx-2 fs-16 fw-400">{vacancies} vacancies</span>
                            </li>
                        </ul>
                        <button type='submit' className='apply-careers my-3' data-bs-toggle="modal" data-bs-target="#applynow" >Apply Now</button>
                    </div>
                </div>

            </div>

            {/* Modal for applying */}
            <div className="modal fade" id="applynow" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form">
                                <div className="row">
                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="resumeName" className="form-label label-color-2 fs-15">Name</label>
                                        <input
                                            type="text"
                                            className="form-control fs-15 label-color-1"
                                            name="resumeName"
                                            id="resumeName"
                                            placeholder="Enter your name"
                                            value={formData.resumeName}
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="number" className="form-label">Number</label>
                                        <input
                                            type="number"
                                            name="resumenumber"
                                            placeholder="Enter Your Number"
                                            className="form-control"
                                            id="number"
                                            value={formData.resumenumber}
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="resumeEmail" className="form-label label-color-2 fs-15 email-mt">Email Address</label>
                                        <input
                                            type="email"
                                            className="form-control fs-15 label-color-1"
                                            name="resumeEmail"
                                            id="resumeEmail"
                                            placeholder="Enter your email address"
                                            value={formData.resumeEmail}
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="currentLocation" className="form-label label-color-2 fs-15 ">Current Location</label>
                                        <input
                                            type="text"
                                            className="form-control fs-15 label-color-1"
                                            name="currentLocation"
                                            id="currentLocation"
                                            placeholder="Enter your current location"
                                            value={formData.currentLocation}
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="currentDesignation" className="form-label label-color-2 fs-15 ">Current Designation</label>
                                        <input
                                            type="text"
                                            className="form-control fs-15 label-color-1"
                                            name="currentDesignation"
                                            id="currentDesignation"
                                            placeholder="Enter your current designation"
                                            value={formData.currentDesignation}
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                    <div className='col-lg-6 mb-3'>
                                        <label htmlFor='selectjobprofile' className="form-label">Select Profile</label>
                                        <select
                                            className="form-select"
                                            id="jobProfile"
                                            name="jobProfile"
                                            value={formData.jobProfile}
                                            onChange={handleFormChange}
                                        >
                                            <option value="">Select to apply for</option>
                                            <option value="Medical Representative">Medical Representative</option>
                                            <option value="Distributors">Distributors</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="apply-careers" onClick={handleFormSubmit}>
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CareersComponent;
