import React, { useEffect } from 'react'
import Form from '../components/Form'
import breadcrumbimage from "../assets/images/Contactbreadcrumb-Us.jpg"
import Breadcrumb from '../components/Breadcrumb'

const Contact = () => {
  useEffect(() => {
    document.title = 'Ebar Healthcare - Contact Us';
  }, []);
  return (
    <>
    <Breadcrumb title="Contact Us" backgroundImage={breadcrumbimage} />
    <div className='main-contact'>
    <Form/>
    </div>
    </>
  )
}

export default Contact
