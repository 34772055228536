import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import breadcrumbimage from '../assets/images/breadpro.jpg'
import Ebacefinjectable from '../mainsubproducts/Injectable/Ebacefinjectable'
import Epaninjectable from '../mainsubproducts/Injectable/Epaninjectable'
import Exxon from '../mainsubproducts/Injectable/Exxon'
import Mpn from '../mainsubproducts/Injectable/Mpn'
import Oeroinjectable from '../mainsubproducts/Injectable/Oreoinjectable'
import Rtnt from '../mainsubproducts/Injectable/Rtnt'
import Sefoinjectable from '../mainsubproducts/Injectable/Sefoinjectable'



const Allinjectable = () => {
  return (
    <>
      <Breadcrumb title="Injectable" backgroundImage={breadcrumbimage} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ebacefinjectable />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Epaninjectable />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Exxon />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Mpn />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Oeroinjectable />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Rtnt />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Sefoinjectable />
          </div>
        </div>
      </div>
    </>
  )
}

export default Allinjectable
