import React, { useEffect } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import breadcrumbimage from '../assets/images/breadpro.jpg'
import Mainproducts from '../components/Mainproducts'
import Downloadpdf from '../assets/images/Productcard.pdf'

const Product = () => {

  useEffect(() => {
    document.title = 'Ebar Healthcare - Products';
  }, []);

  return (
    <>
      <Breadcrumb title="Products" backgroundImage={breadcrumbimage} />
      <div className='mt-5'>
        <Mainproducts />
      </div>
      <div className='container mt-4 mb-5'>
        <div className='row'>
          <div className='col-12'>
            <a className='download_card' href={Downloadpdf} download>Download Card</a>
          </div>
        </div>
      </div>

    </>
  )
}

export default Product
