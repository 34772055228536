import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom'
import Navbar from './components/Navbar';
import Home from "./pages/Home"
import about from './pages/about';
import Contact from './pages/Contact';
import Manufacturing from './pages/Manufacturing';
import Careers from './pages/Careers';
import Product from './pages/Product';
import Footer from './components/Footer';
import Mainproducts from './components/Mainproducts';
import Allantibiotics from './allmainproducts/Allantibiotics';
import Allnutritional from './allmainproducts/Allnutritional';
import Allpainkiller from './allmainproducts/Allpainkiller';
import Allinjectable from './allmainproducts/Allinjectable';
import Allgynecrange from './allmainproducts/Allgynecrange';
import Allcoughcold from './allmainproducts/Allcoughcold';
import Allantiulcerant from './allmainproducts/Allantiulcerant';
import Allothers from './allmainproducts/Allothers';

function App() {

  const alertwelcomemessage = () => {
    alert("Welcome To Ebar Healthcare!")
  }

  window.onload = function () {
    if (window.location.href === "http://localhost:3000/") {
      alertwelcomemessage();
      setTimeout(function () {
      }, 3000);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path='/' Component={Home}></Route>
        <Route path='/about' Component={about}></Route>
        <Route path='/products' Component={Product}></Route>
        <Route path='/manufacturing' Component={Manufacturing}></Route>
        <Route path='/careers' Component={Careers}></Route>
        <Route path='/contact' Component={Contact}></Route>
        <Route path="/" element={<Mainproducts />} />
        <Route path="/allantibiotics" element={<Allantibiotics />} />
        <Route path="/allnutritional" element={<Allnutritional />} />
        <Route path="/allpainkiller" element={<Allpainkiller />} />
        <Route path="/allinjectable" element={<Allinjectable />} />
        <Route path="/allgynecrange" element={<Allgynecrange />} />
        <Route path="/allcoughcold" element={<Allcoughcold />} />
        <Route path="/allantiulcerant" element={<Allantiulcerant />} />
        <Route path="/allothers" element={<Allothers />} />
      </Routes>
      <Footer />
    </>
  );
}
export default App;