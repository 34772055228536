import React, { useState } from 'react';
import CareersComponent from './CareersComponent';

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const careerData1 = {
    title: 'Medical Representative',
    vacancies: 2,
    time: 'Full-time',
    designation: ' Senior Medical Representative',
  };
  const careerData2 = {
    title: 'Distributors',
    vacancies: 2,
    time: 'Full-time',
    designation: 'Senior Distributors',
  };
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-lg-6 col-12 mb-3 " data-aos="fade-up"  data-aos-duration="1000">
          <button
            className={`custom-btn-tab tab-button-style ${activeTab === 1 ? 'active-tab' : ''}`}
            onClick={() => handleTabClick(1)}
          >
            Medical Representative
          </button> 
        </div>
        <div className="col-lg-6 col-12 mb-3 " data-aos="fade-up"  data-aos-duration="1000">
          <button
            className={`custom-btn-tab tab-button-style ${activeTab === 2 ? 'active-tab' : ''}`}
            onClick={() => handleTabClick(2)}
          >
            Distributor
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 " data-aos="fade-up"  data-aos-duration="1000">
          {activeTab === 1 && <div className="mt-3 p-3">
            <CareersComponent {...careerData1} />
          </div>}
          {activeTab === 2 && <div className="mt-3 p-3">
            <CareersComponent {...careerData2} />
          </div>}
        </div>
      </div>
    </div>
  );
};

export default TabComponent;
