const Manufacture = [
    {
        Step1: "Quality",
        content1: "The company must produce high-quality products that meet the required standards of safety and efficacy."
    },
    {
        Step2: "Compliance",
        content2: "The company must comply with all applicable laws and regulations."
    },
    {
        Step3: "Innovation",
        content3: "The company must continually innovate to develop new products and improve its processes."
    },
    {
        Step4: "Sustainability",
        content4: "The company must operate in a sustainable manner, taking into account environmental and social impacts."
    },
];

export default Manufacture;
