import React from 'react'
import { Link } from 'react-router-dom';

const Abtcareer = () => {

    return (
     <>
     <div className='container my-5 bg-theme_color ' data-aos="fade-up"  data-aos-duration="1000">
      <div className='row'>
          <div className='col-lg-9 col-md-9 col-sm-6 col-12'>
              <p className='some-info-career'>We invite you to join us on this exciting journey of scientific discovery and growth. Together, we can make a significant impact on healthcare and improve the lives of countless individuals.</p>
          </div>
          <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
              <Link className='btn btn-light mt-2' id='join_now' to='/careers' style={{width:"100%"}} > Join Now </Link>
          </div>
      </div>
     </div>
     </>
    )
}

export default Abtcareer