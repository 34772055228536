import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import breadcrumbimage from '../assets/images/breadpro.jpg'
import Bes from '../mainsubproducts/Antiulcerant/Bes'
import Epan from '../mainsubproducts/Antiulcerant/Epan'
import Esomal from '../mainsubproducts/Antiulcerant/Esomal'
import Gpo from '../mainsubproducts/Antiulcerant/Gpo'
import Omal from '../mainsubproducts/Antiulcerant/Omal'
import Oreo from '../mainsubproducts/Antiulcerant/Oreo'
import Rpl from '../mainsubproducts/Antiulcerant/Rpl'




const Allantiulcerant = () => {
  return (
    <>
      <Breadcrumb title="Anti Ulcerant" backgroundImage={breadcrumbimage} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Bes />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Epan />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Esomal />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Gpo />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Omal />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Oreo />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Rpl />
          </div>
        </div>
      </div>
    </>
  )
}

export default Allantiulcerant
