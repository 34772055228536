import React, {useEffect} from "react";
import Slider from "../components/slider";
import Products from "../components/Products";
import Textleft from "../components/Textleft";
import Textright from "../components/Textright";
import aboutimage from "../assets/images/aboutimage.jpg";
import indiamap from '../assets/images/india.jpg'
import Abtcareer from "../components/Abtcareer";
const Home = () => { 
  useEffect(() => {
    document.title = 'Ebar Healthcare - Home';
  }, []);
  return (
    <>
      <Slider />
      <div className="right-colimage">
      <Textleft 
        title="Welcome to "
        heading="Ebar Healthcare "
        description1="EBAR HEALTHCARE is a pharmaceutical company established in 2007 that initially started production and marketing from one plant at Santej, Ahmedabad and another at Rudrapur, Uttrakhand.
        It is a leading pharmaceutical company in India, with its director having vast experience of 30 years in the industry.  
        "
        description2="The company operates in 16 states and has a wide range of products, including Nutra, gynec, derma, opthal and general physician range."
        imagePath={aboutimage}
        linkTo="/about"
      />
      </div>
      <Products />
      <Textright
        heading="Manufacturing"
        description1="Ebar Healthcare is committed to all aspects of the pharmaceutical manufacturing process, from drug discovery to drug distribution. The company is committed to providing safe and effective products that meet the needs of patients around the world."
        description2="EBAR HEALTHCARE manufactures a wide range of healthcare solutions at its WHO-GMP certified facilities, guided by its commitment to good health and passion for innovation."
        imagePath={indiamap}
        linkTo="/manufacturing"
      />
      <Abtcareer/>
    </>
  );
};

export default Home;
