import React from 'react';
import { Link } from 'react-router-dom';

const Textleft = ({ title,heading, description1, description2, imagePath}) => {
  return (
    <section className="creative-agency-about-area clinic-about-area" id='background-image_textleft' data-scroll-index="2">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="ca-about-left clinic-about-left " data-aos="fade-up" data-aos-duration="1000" >
              <h5 className='wlc'>{title}</h5>
              <h2 className='ebar-heading'>{heading}</h2>
              <p>{description1}</p>
              <p>{description2}</p>
              <div className="ca-about-btn-wrapper clinic-about-btn-wrapper">
                <Link className="ca-about-btn clinic-about-btn" to="/about">Learn More</Link>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 d-flex justify-content-center mt-5">
            <div className="ca-about-right clinic-about-right">
              <div className="ca-about-img clinic-about-img">
                <img className='img-fluid ' data-aos="fade-down"  data-aos-duration="1000" src={imagePath} alt="About" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Textleft;
