import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import breadcrumbimage from '../assets/images/breadpro.jpg'
import Ditc from '../mainsubproducts/Gynecrange/Ditc'
import Dob from '../mainsubproducts/Gynecrange/Dob'
import Ebafolgr from '../mainsubproducts/Gynecrange/Ebafolgr'
import Ebaverin from '../mainsubproducts/Gynecrange/Ebaverin'
import Flubar from '../mainsubproducts/Gynecrange/Flubar'
import Hebaodz from '../mainsubproducts/Gynecrange/Hebaodz'
import Hebasfsyrup from '../mainsubproducts/Gynecrange/Hebasfsyrup'
import Hebaxt from '../mainsubproducts/Gynecrange/Hebaxt'
import Isp from '../mainsubproducts/Gynecrange/Isp'
import Muci from '../mainsubproducts/Gynecrange/Muci'



const Allgynecrange = () => {
  return (
    <>
      <Breadcrumb title="Gynec Range" backgroundImage={breadcrumbimage} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ditc />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Dob />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ebafolgr />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Ebaverin />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Flubar />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Hebaodz />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Hebasfsyrup />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Hebaxt />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Isp />
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <Muci />
          </div>
        </div>
      </div>
    </>
  )
}

export default Allgynecrange
