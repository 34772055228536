const Abtdata = [
    {
        id: 1,
       info:"Time Management"
    },  
    {
        id: 2,
       info:"Customer Satisfaction"
    },  
    {
        id: 3,
        info:"Quality Products"
    },  
]
export default Abtdata